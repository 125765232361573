import { Entities } from '@inwink/entities/entities';
import { logging, ILogger } from '@inwink/logging';
import { States } from '../../../services/services';
import { getDb } from '../../../data';
import { Collection } from '@inwink/inwinkdb';

const logger = logging.getLogger("Data");

export function fromEventJSON(eventid: string, jsonDB): States.IEventDataStore {
    const data = newEventData(eventid);
    if (!jsonDB) {
        initEventDataStore(data);
        if ((global as any).inwinkEventBootstrap) {
            if ((global as any).inwinkEventBootstrap.main) {
                data.db.loadJSONObject((global as any).inwinkEventBootstrap.main.data);  
            } else {
                const newCollections : Collection<any>[] = [];
                data.db._collections.forEach((c) => {
                    const collectionData = (global as any).inwinkEventBootstrap[c.name];
                    if (collectionData) {                
                        newCollections.push(Collection.fromJSONObject(collectionData));
                    } else {
                        newCollections.push(c);
                    }
                });
                data.db._collections = newCollections;
            }
        }
        
    } else if (typeof jsonDB === "object") {
        data.db.loadJSONObject(jsonDB);    
    } else {
        data.db.loadJSON(jsonDB);
    }
    data.ready = Promise.resolve(data);
    initEventDataStore(data);
    return data;
}

export function newEventData(eventid: string, options?: any) {
    const db = getDb(eventid, options);

    return <States.IEventDataStore>{
        db: db,
        eventId: eventid,
        hasData: false,
        hasFullData: false,
        syncInProgess: false,
        eventDetail: null,
        sessions: null,
        speakers: null,
        exhibitors: null,
        exhibitoraccounts: null,
        exhibitorofferings: null,
        eventtimelineactivities: null,
        eventthemes: null,
        journeys: null,
        eventmessage: null,
        templates: null,
        fieldtemplates: null,
        entityformstemplates: null,
        surveys: null,
        eventavailabilities: null,
        lastsyncs: null,
        ready: null,
        savingPromise: Promise.resolve(),
        save() {
            const promise = this.savingPromise.then(() => {
                return db.saveDatabase();
            });

            const safePromise = promise.then(null, () => { });

            this.savingPromise = safePromise;
            const cleanup = () => {
                if (this.savingPromise === safePromise) {
                    this.savingPromise = Promise.resolve();
                }
            };

            safePromise.then(cleanup);

            return promise;
        }
    };
}

function initEventDataStore(eData: States.IEventDataStore) {
    const eventData = eData;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const getDbCollection = function coll<T extends object>(name, options) {
        let col = eventData.db.getCollection<T>(name);
        if (!col) col = eventData.db.addCollection<T>(name, options);

        return col;
    };

    eventData.eventDetail = getDbCollection<Entities.IEventDetail>('ed', { unique: ['id'] });
    eventData.sessions = getDbCollection<Entities.ISession>('ses', { unique: ['id'] });
    eventData.speakers = getDbCollection<Entities.ISpeaker>('spe', { unique: ['id'] });
    eventData.exhibitors = getDbCollection<Entities.IExhibitor>('exh', { unique: ['id'] });
    eventData.exhibitoraccounts = getDbCollection<Entities.IExhibitorAccount>('exha', { unique: ['id'] });
    eventData.exhibitorofferings = getDbCollection<Entities.IExhibitorOffering>('exho', { unique: ['id'] });
    eventData.eventtimelineactivities = getDbCollection<Entities.IEventTimelineActivity>( 'etml', { unique: ['id'] } );
    eventData.eventthemes = getDbCollection<Entities.IEventTheme>('et', { unique: ['id'] });
    eventData.journeys = getDbCollection<Entities.IJourney>('jou', { unique: ['id'] });
    eventData.eventmessage = getDbCollection<Entities.IEventMessage>('em', { unique: ['id'] });
    eventData.templates = getDbCollection<Entities.IContentTemplate>('tpl', { unique: ['id'] });
    eventData.fieldtemplates = getDbCollection<Entities.IFieldTemplate>('ftpl', { unique: ['entityName'] });
    eventData.entityformstemplates = getDbCollection<Entities.IExtendedFieldsFormTemplate>('fotpl', { unique: ['entityName'] });
    eventData.lastsyncs = getDbCollection<Entities.ILastSync>('ls', { unique: ['key'] });
    eventData.surveys = getDbCollection<Entities.ISurvey>('sv', { unique: ['id'] });
    eventData.eventavailabilities = getDbCollection<Entities.IEventAvailability>('eav', { unique: ['id'] });

    if (eventData.templates.count() > 0) {
        eventData.hasData = true;
    }
    if (eventData.fieldtemplates.count() > 0) {
        eventData.hasFullData = true;
    }
}

export function getEventRepository(
    eventid: string, options?: any, disableRead?: boolean, currentlogger?: ILogger
): States.IEventDataStore {
    const eventData = newEventData(eventid, options);

    eventData.ready = new Promise<States.IEventDataStore>((dbComplete, dbError) => {
        if (disableRead) {
            initEventDataStore(eventData);

            dbComplete(eventData);
        } else {
            eventData.db.loadDatabase(null, true).then(() => {
                initEventDataStore(eventData);

                dbComplete(eventData);
            }, dbError);
        }
    }).then((data) => {
        eventData.ready = Promise.resolve(data);
        return data;
    }, (err) => {
        (currentlogger || logger).error("error getting event repository", err);
        eventData.ready = Promise.resolve();
        return Promise.reject(err);
    });

    return eventData;
}
