import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { journeyDatasourceV3 } from '../../api/journey';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';

export function syncJourneys(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args.updatedEntityTemplates && args.updatedEntityTemplates.some((t) => t.entityName.toLowerCase() === "journey")) {
        hasTemplateUpdate = true;
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom"}],
            selects: {
                $all: true
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    return syncRecursiveEntity<Entities.IJourney>({
        datasource: journeyDatasourceV3(args.eventRequests.apiFront),
        getBaseOptions: getExpr,
        logger: args.logger,
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        collectionname: "journeys",
        itemcallback: (_j) => {
            const j = _j;
            hasChanges = true;
            j.speakers = j.speakers ? j.speakers.map((speaker) => <any>{ id: speaker.id }) : null;
            j.sessions = j.sessions ? j.sessions.map((session) => <any>{ id: session.id }) : null;
            j.exhibitors = j.exhibitors ? j.exhibitors.map((exhibitor) => <any>{ id: exhibitor.id }) : null;
            j.exhibitorOfferings = j.exhibitorOfferings ?
                j.exhibitorOfferings.map((exhibitorOffering) => <any>{ id: exhibitorOffering.id }) : null;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
