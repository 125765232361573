import type { Entities } from '@inwink/entities/entities';
import type { IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function exhibitorDatasourceV3(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IExhibitor>(requestManager, "exhibitor", "Exhibitor");
    });
}

export function exhibitorAccountDatasourceV3(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IExhibitorAccount>(requestManager, "exhibitoraccount", "ExhibitorAccount");
    });
}
