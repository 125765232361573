import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { eventThemeDatasourceV3 } from '../../api/eventtheme';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';

export function syncEventThemes(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args.updatedEntityTemplates && args.updatedEntityTemplates.some((t) => t.entityName.toLowerCase() === "eventtheme")) {
        hasTemplateUpdate = true;
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom"}],
            selects: {
                $all: true
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    return syncRecursiveEntity<Entities.IEventTheme>({
        datasource: eventThemeDatasourceV3(args.eventRequests.apiFront),
        getBaseOptions: getExpr,
        logger: args.logger,
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        collectionname: "eventthemes",
        itemcallback: (_s) => {
            const s = _s;
            hasChanges = true;
            s.sessions = s.sessions ? s.sessions.map((sp) => <any>{ id: sp.id }) : null;
            s.exhibitors = s.exhibitors ? s.exhibitors.map((sp) => <any>{ id: sp.id }) : null;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
