import { Entities } from '@inwink/entities/entities';
import { initEvent } from '@@event/features';
import { actions as i18nActions } from '@@services/i18nservice';
import { States } from "@@services/services";
import { asyncReducers } from "@@services/index";
import { IEventRequests } from '@@services/apiaccessprovider.definition';
import { currentUserActions } from '@@event/services/useractions/currentuser';
import { setEventInfoFor } from '../../../../client/eventinfo';
import { eventReducer } from "../eventservice";

export { initEventInfoProvider } from '../../../../client/eventinfo';
export { AppShell } from '@@event/shell/appshell';
export { fromEventJSON } from '@@event/data/eventdata';

asyncReducers.event = eventReducer;
initEvent();

export const initEventActions = {
    setCurrentEvent(
        eventRequests: IEventRequests,
        event: Entities.IEventDetail,
        data: States.IEventDataStore,
        lngcode:string,
        awaitUserCheck: boolean,
        req?
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            let trackingPromise : Promise<any> = Promise.resolve();

            if (!__SERVERSIDE__) {
                setEventInfoFor(event.tinyUrl, {
                    eventId: event.id,
                    authTenantId: event.tenantId,
                    customerId: event.customerId
                });
                const trackingConfig = event?.configuration?.companion?.tracking;
                const cookiebarConfig = event?.configuration?.companion?.cookiesWarning;
                trackingPromise = import("@@services/tracking/actions").then((mod) => {
                    return mod.trackingServiceActions.init(
                        event.id, event.id, null, trackingConfig, cookiebarConfig
                    )(dispatch, getState);
                });
            }
            const payload: any = {
                event,
                data,
                requestManagers: eventRequests
            };
            const eventconf = event && event.configuration;
            const isLockedEvent = eventconf && eventconf.companion && eventconf.companion.requireAuthenticationForContent;
            if (!isLockedEvent || __SERVERSIDE__) {
                payload.eventReady = true;
            }
            dispatch({ type: 'EVENT_SET', payload });

            const initLanguage = i18nActions.autoinit(lngcode, req)(dispatch, getState);

            return Promise.all([initLanguage, trackingPromise]).then(() => {
                if (awaitUserCheck || isLockedEvent) {
                    return currentUserActions.checkCurrentUser()(dispatch, getState).then(null, (err) => {
                        console.error("error checking user", err);
                    });
                }

                currentUserActions.debouncedCheckCurrentUser(dispatch, getState);

                // setTimeout(() => {
                //     currentUserActions.checkCurrentUser()(dispatch, getState).then(null, (err) => {
                //         console.error("error checking user", err);
                //     });
                // }, 300);

                if (InWinkPreview) {
                    if (typeof window !== "undefined" && window.parent) {
                        window.parent.postMessage({
                            type: "CurrentEvent",
                            event
                        }, "*");
                    }
                }
            });
        };
    },

    // preloadEvent(eventid: string, tinyurl: string, tenantid: string) {
    //     return (dispatch, getState: () => States.IAppState) => {
    //         return new Promise<any>((resolve, reject) => {
    //             const oidc = oidcmgr.for({ event: eventid, tenant: tenantid });
    //             const oidcinit = oidc.initialize();
    //             const eventrequests = getEventRequests(eventid, oidc, oidcinit);
    //             const eventData = getEventRepository(eventid);
    //             eventData.ready.then(() => {
    //                 if (eventData.hasData) {
    //                     logger.debug("event has local data");
    //                     const detail = eventData.eventDetail.data[0];
    //                     return initEventActions.setCurrentEvent(eventrequests, detail,
    // eventData, "", false)(dispatch, getState);
    //                 }
    //                 // event jamais chargé
    //                 return oidcinit;
    //             }).then(() => {
    //                 eventData.hasFullData = false;
    //                 return oidcinit;
    //             }).then(() => {
    //                 logger.debug("event preloaded");
    //             }).then(resolve, reject);
    //         });
    //     };
    // },
};
