import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import type { IInwinkEntityV3QueryOptions } from 'api/front';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { eventTimelineActivityDatasourceV3 } from '../../api/eventtimelineactivities';

export function syncEventTimelineActivities(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    if (!args.eventDetail) {
        if (args.trackProgress) {
            args.trackProgress({ collectionname: "eventtimelineactivities", progressPercent: 100 });
        }
        return Promise.resolve();
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom" }],
            selects: {
                $all: true
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    const itemkey = (r, s) => { return r.id === s.id; };
    const itemcallback = () => {
        hasChanges = true;
    };

    return syncRecursiveEntity<Entities.IEventTimelineActivity>({
        datasource: eventTimelineActivityDatasourceV3(args.eventRequests.apiFront),
        logger: args.logger,
        data: args.eventData,
        force: args.force,
        getBaseOptions: getExpr,
        collectionname: "eventtimelineactivities",
        itemkey: itemkey,
        itemcallback: itemcallback,
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
