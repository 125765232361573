import type { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import type { States } from "@@services/services";
import { appSplashActions } from '@@services/splashservice';
import { eventModule } from '@@routes/appmodules';
import { getVisualConfiguration } from '@@data/templates';
import { getEventIdAsync, shouldForceSync, hasData, allowBgFullSync } from '@@event/sync/sync.helpers';
import { getEventRepository } from '@@event/data/eventdata';
import { actions as pageActions } from '@@services/pageservice';
import { appsplashuserservice } from '@@services/splashservice.codes';
import { getAccessManager } from '@@services/apiaccessprovider';
import type { IEventRequests } from '@@services/apiaccessprovider.definition';
import { clearLastSync } from "@@data/globalsync";
import { eventCheckForUser } from "./eventservice.checkuser";
import { syncEventData } from "./eventservice.syncevent";
import { initEventActions } from "./eventactions/init";
import { trackEventError } from '@@event/api/tracking';

const datalogger = logging.getLogger("Data");

export const appsplashservice = "eventbootstrap";

function setEventReady(dispatch, getState: () => States.IAppState) {
    const state = getState();
    if (!(state.event && state.event.eventReady)) {
        dispatch({ type: 'EVENT_ISREADY' });
    }
}

export function eventBootstrap(
    isFromServer: boolean,
    tmpeventid: string,
    tinyurl: string,
    location: States.ILocation,
    matches: States.ILocationMatch,
    forceload: boolean,
    dispatch,
    getState: () => States.IAppState,
    retries = 0
): Promise<any> {
    if (__SERVERSIDE__) {
        return Promise.resolve();
    }

    if (console && console.timeStamp) {
        console.timeStamp("bootstrap event");
    }

    const state = getState();
    let eventState = state.event;
    let visualconfig: Entities.IVisualConfigurationTemplate = null;

    if (eventState?.data) {
        visualconfig = getVisualConfiguration(eventState.data);
    }

    if (forceload) {
        if (state.rootwebsite.data) {
            visualconfig = getVisualConfiguration(state.rootwebsite.data);
        }
        eventState = {
            tinyUrl: tinyurl
        } as any;
        appSplashActions.setVisualConfig(visualconfig.splashscreen)(dispatch);
    }

    appSplashActions.registerService(appsplashservice, {
        hasError: false,
        progress: null,
        onRetry: () => {
            clearLastSync();
            eventBootstrap(false, tmpeventid, tinyurl, location, matches, false, dispatch, getState, retries + 1);
        }
    }, visualconfig)(dispatch, getState);

    const preparePromise = state.event?.requestManagers
        ? Promise.resolve(state.event?.requestManagers)
        : getAccessManager().then((mgr) => mgr.getEventRequests(tmpeventid || tinyurl, state.event?.tenantid));

    return preparePromise.then((eventRequests) => {
        return prepareEvent(
            isFromServer,
            visualconfig,
            eventRequests,
            tmpeventid,
            tinyurl,
            location,
            matches,
            dispatch,
            getState
        );
    }).then(() => {
        // if (retries === 0) {
        //     return Promise.reject(new Error("oups"));
        // }
        appSplashActions.unregisterService(appsplashservice)(dispatch, getState);
    }).then(null, (err) => {
        console.error("error initializing event (retries " + retries + ")", err);
        const errstate = getState();
        const canRetry = isFromServer && retries < 2;

        if (canRetry) {
            return new Promise((resolve) => {
                setTimeout(resolve, 50);
            }).then(() => eventBootstrap(false, tmpeventid, tinyurl, location, matches, false, dispatch, getState, retries + 1));
        }

        if (errstate.event.eventid && errstate.event?.requestManagers?.apiFront) {
            let loc = typeof window !== "undefined" && window.location.href;
            if (location) {
                loc = location.pathname + location.search;
            }
            trackEventError(
                errstate.event.requestManagers.apiFront,
                errstate.event.eventid,
                err,
                "error bootstrapping event (retries " + retries + ")",
                loc
            ).then(null, (trackerr) => {
                console.error("could not track error", trackerr);
            });
        }

        appSplashActions.progress(appsplashservice, {
            progress: null,
            hasError: true,
            message: "sync.error"
        })(dispatch);

        return Promise.reject(err);
    });
    // debugger;
}

function prepareEvent(
    isFromServer: boolean,
    visualconfig: Entities.IVisualConfigurationTemplate,
    sourceeventRequests: IEventRequests,
    tmpeventid: string,
    tinyurl: string,
    location: States.ILocation,
    matches: States.ILocationMatch,
    dispatch,
    getState: () => States.IAppState
) {
    let eventRequests = sourceeventRequests;
    const state = getState();

    return getEventId(
        eventRequests,
        tmpeventid,
        tinyurl,
        state.event?.tenantid,
        state.event?.customerid,
        dispatch
    ).then((eventids) => {
        if (eventRequests.eventid === tinyurl) {
            return getAccessManager().then(
                (mgr) => mgr.getEventRequests(eventids.eventId, eventids.authTenantId)
            ).then((eventRequestsFromId) => {
                eventRequests = eventRequestsFromId;
                return eventids;
            });
        }
        return eventids;
    }).then((eventids) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        let state = getState();

        const data = state.event?.data || getEventRepository(eventids.eventId);
        return data.ready.then((eventData: States.IEventDataStore) => {
            state = getState();
            const eventdetail = eventData.eventDetail.data.find((e) => e.id === eventids.eventId);
            const eventHasData = hasData(eventData) && eventData.hasFullData;

            const eventconf = eventdetail && eventdetail.configuration;
            const isLockedEvent = eventconf && eventconf.companion && eventconf.companion.requireAuthenticationForContent;
            if (isLockedEvent && !state.user.checked) {
                appSplashActions.registerService(appsplashuserservice, null, visualconfig)(dispatch, getState);
            }

            if (isFromServer) {
                if (isLockedEvent) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    return startupPageData(
                        eventids.eventId,
                        eventRequests,
                        location,
                        matches,
                        eventData,
                        dispatch,
                        getState
                    );
                }
                return;
            }

            let doBgSync = true;
            const force = shouldForceSync(eventData);
            if (force) {
                doBgSync = allowBgFullSync(eventData);
            }

            if (!eventHasData || !doBgSync) {
                // Si on n'a pas de données on fait une synchro et on attend la fin avant d'afficher la page
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return syncEventData(
                    eventRequests,
                    appsplashservice,
                    eventids.eventId,
                    eventData,
                    force,
                    false,
                    dispatch,
                    getState
                ).then((syncres) => {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    const eventData = (syncres && syncres.eventData) || data;
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    return startupPageData(
                        eventids.eventId,
                        eventRequests,
                        location,
                        matches,
                        eventData,
                        dispatch,
                        getState
                    );
                });
            }

            if (!isFromServer) { // (shouldSync(eventdetail, eventData)) {
                // on entre dans l'appli sans attendre et on synchro en arrière plan

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return startupPageData(
                    eventids.eventId,
                    eventRequests,
                    location,
                    matches,
                    eventData,
                    dispatch,
                    getState
                ).then(() => {
                    eventModule().then((mod) => {
                        mod.syncEventActions.syncEventData(eventRequests, eventids.eventId, eventData, force)(dispatch, getState);
                    });
                });
            }

            // Sinon on a des données fraîches on rentre directement
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return startupPageData(eventids.eventId, eventRequests, location, matches, eventData, dispatch, getState);
        }).then(() => {
            // let eventData = (res && res.eventData) || data;
            // return startupPageData(eventid, location, matches, eventData, dispatch, getState);
        });
    }).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const state = getState();
        const eventdetail = state.event?.detail;
        const eventconf = eventdetail?.configuration;
        const isLockedEvent = eventconf?.companion?.requireAuthenticationForContent;

        if (isLockedEvent) {
            clearLastSync();
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            eventCheckForUser(dispatch, getState);
        } else {
            return setEventReady(dispatch, getState);
        }
    });
}

function startupPageData(
    eventid: string,
    eventRequests: IEventRequests,
    location: States.ILocation,
    matches: States.ILocationMatch,
    eventData: States.IEventDataStore,
    dispatch,
    getState: () => States.IAppState
): Promise<any> {
    const eventdetail = eventData.eventDetail.data.find((e) => e.id === eventid);
    if (eventdetail) {
        const action = initEventActions.setCurrentEvent(
            eventRequests,
            eventdetail,
            eventData,
            matches.params.lngcode,
            false
        )(dispatch, getState);

        return action.then(() => {
            const globalstate = getState() as States.IAppState;
            const pages = globalstate.pages;

            const currentPage = pages.currentPage ? pages[pages.currentPage.id] : null;

            const isPrivate = globalstate?.event?.detail?.configuration?.companion?.requireAuthenticationForContent;
            let noUserLoggedInApp = false;
            if (!currentPage && (!globalstate.user || !globalstate.user.currentUser) && isPrivate) {
                noUserLoggedInApp = true;
            }

            // if (currentPage || noUserLoggedInApp) {
            if (isPrivate && noUserLoggedInApp) {
                const locals = {
                    location: location,
                    match: matches,
                    dispatch: dispatch
                };

                if (console && console.timeStamp) {
                    console.timeStamp("startup ended, init current page");
                }

                datalogger.debug("startup ended, init current page");
                // On doit repousser les données de la page courante
                return pageActions.initCurrentPage(
                    locals,
                    "nouserlogged",
                    {
                        entityKind: currentPage && currentPage.context ? currentPage.context.entityKind : null,
                        entityid: currentPage && currentPage.context ? currentPage.context.entityid : null,
                        contenttype: currentPage?.context?.contenttype || (noUserLoggedInApp ? "page" : null)
                    }
                )(dispatch, getState);
            }

            if (console && console.timeStamp) {
                console.timeStamp("event startup ended");
            }

            if (!currentPage) {
                console.warn("strange navigation behavior... please investigate it "
                    + (pages.currentPage ? pages.currentPage.id : "no-current"));
            }
        });
    }

    return Promise.resolve();
}

function getEventId(
    eventRequests: IEventRequests,
    eventid: string,
    tinyurl: string,
    tenantid: string,
    customerid: string,
    dispatch
): Promise<{ eventId: string, authTenantId: string, customerId: string }> {
    if (eventid) {
        return Promise.resolve({
            eventId: eventid,
            authTenantId: tenantid,
            customerId: customerid
        });
    }

    if (tinyurl) {
        return getEventIdAsync(eventRequests, tinyurl, dispatch);
    }

    return Promise.reject(new Error("Unknown event, neither eventid or tinyurl is available"));
}
