import * as assign from 'lodash/assign';
import type { Entities } from '@inwink/entities/entities';
import type { ILogger } from '@inwink/logging';
import { metadataSyncActions } from '@@services/appmetadataactions/sync';
import type { States } from '@@services/services';
import { getLastSync } from '@@data/globalsync';
import type { IEventEntitySync } from '@@data/syncutils';
import type { IEventRequests } from '@@services/apiaccessprovider.definition';
import { actions as eventActions } from '../services/eventservice';
import { getEventDetail } from '../api/event';
import { syncFieldTemplate } from './eventsync/entitytemplates';
import { syncContentTemplates } from './eventsync/contenttemplates';
import { syncEventThemes } from './eventsync/eventthemes';
import { syncEventAvailabilities } from './eventsync/eventavailability';
import { syncSurvey } from './eventsync/surveys';
import { syncSpeakers } from './eventsync/speakers';
import { syncSessions } from './eventsync/sessions';
import { syncExhibitor } from './eventsync/exhibitors';
import { getEventRepository } from '../data/eventdata';
import { syncEventTimelineActivities } from './eventsync/eventtimelineactivities';
import { syncExhibitorOfferings } from './eventsync/exhibitorofferings';
import { syncJourneys } from './eventsync/journey';

const pendingSync: Record<string, Promise<any>> = {};

export function coreSync(
    logger: ILogger,
    eventRequests: IEventRequests,
    eventid: string,
    eData: States.IEventDataStore,
    force: boolean,
    dispatch,
    getState: () => States.IAppState,
    progressCallback?: (arg: { progressPercent: number }) => void,
    disableSave?: boolean,
    disableFrequentSyncCheck?: boolean
): Promise<{ hasChanges: boolean, eventData: States.IEventDataStore }> {
    let eventData = eData;
    const globalLastSync = getLastSync();

    if (pendingSync[eventid]) {
        logger.debug("DATA sync is already in progress");
        return pendingSync[eventid];
    }

    if (force) {
        eventData = getEventRepository(eventid, null, true);
    }

    const diff = (new Date() as any) - ((globalLastSync[eventid] as any) || 0);
    if (!force && diff < 5000 && !disableFrequentSyncCheck) {
        if (__SERVERSIDE__) {
            logger.debug("DATA too frequent sync...");
        } else {
            logger.info("DATA too frequent sync...");
        }
        return Promise.resolve({ hasChanges: false, eventData: eventData });
    }

    eventData.syncInProgess = true;
    globalLastSync[eventid] = new Date();

    if (dispatch) {
        metadataSyncActions.isSyncingEventData(true)(dispatch);
    }

    const progressSteps = [
        { name: "eventdetail", progress: 0, coeff: 0.2 },
        { name: "templates-event", progress: 0, coeff: 0.5 },
        { name: "templates-companion", progress: 0, coeff: 0.5 },
        { name: "fieldtemplates", progress: 0, coeff: 0.5 },
        { name: "sessions", progress: 0, coeff: 1 },
        { name: "exhibitors", progress: 0, coeff: 1 },
        { name: "exhibitoraccounts", progress: 0, coeff: 1 },
        { name: "exhibitorofferings", progress: 0, coeff: 1 },
        { name: "eventtimelineactivities", progress: 0, coeff: 1 },
        { name: "eventthemes", progress: 0, coeff: 1 },
        { name: "speakers", progress: 0, coeff: 1 },
        { name: "surveys", progress: 0, coeff: 0.5 },
        { name: "save", progress: 0, coeff: 0.2 },
    ];

    const progress = {
        progressPercent: 0
    };

    const trackProgress = (arg: { collectionname: string, message?: string, progressPercent: number }) => {
        const current = progressSteps.filter((s) => s.name === arg.collectionname)[0];
        if (current) {
            current.progress = arg.progressPercent;
        }

        let currentProgress = 0;
        let currentCoeffs = 0;
        currentProgress = progressSteps.reduce((prev, c) => {
            currentCoeffs += c.coeff;
            return prev + (c.coeff * c.progress);
        }, currentProgress);
        progress.progressPercent = (currentProgress / currentCoeffs);

        if (progressCallback) progressCallback(progress);
    };

    let hasChanges = false;
    
    const trackChanges = (res) => {
        hasChanges = hasChanges || !!res;
        return res;
    };

    let syncEventDetail: Entities.IEventDetail;
    let syncArgs: IEventEntitySync = null;
    eventData.syncInProgess = true;
    logger.verbose("DATA syncing event detail force:" + force);

    let timeout = 5000;
    if (__SERVERSIDE__) {
        timeout = 20000;
    }
    // il faut laisser un appel simple pour démarrer la synchro pour s'assurer que l'access token
    // est toujours ok et que la connexion est up
    // ensuite on peut parallèliser
    const syncpromise = getEventDetail(eventRequests, timeout).then((eventdetail) => {
        syncEventDetail = eventdetail;
        eventData.exhibitoraccounts.clear();
        if (eventData.eventDetail && eventData.eventDetail.data.length) {
            const existing = eventData.eventDetail.data[0];
            assign(existing, eventdetail);
            eventData.eventDetail.update(existing);
        } else {
            eventData.eventDetail.insert(eventdetail);
        }

        syncArgs = {
            eventDetail: eventdetail,
            configuration: eventdetail && eventdetail.configuration,
            eventRequests: eventRequests,
            dispatch: dispatch,
            getState: getState,
            eventData: eventData,
            force: force,
            logger: logger,
            trackProgress: trackProgress,
            rewriteUrls: null
        };
        if (inwink.config.externalAssetsUrl && inwink.config.externalAssetsCDN) {
            syncArgs.rewriteUrls = [{
                source: inwink.config.externalAssetsUrl,
                target: inwink.config.externalAssetsCDN
            }];
        }
    }).then(() => {
        trackProgress({ collectionname: "eventdetail", progressPercent: 100 });
    }, (err) => {
        trackProgress({ collectionname: "eventdetail", progressPercent: 100 });
        return Promise.reject(err);
    }).then<any>(() => {
        return syncContentTemplates(syncArgs).then(trackChanges);
    }).then<any>(() => {
        logger.verbose("DATA syncing event data");
        
        if (getState && syncEventDetail.configuration?.companion?.requireAuthenticationForContent) {
            const st = getState();
            if (!st.user || !st.user.currentUser) {
                return Promise.resolve(false);
            }
        }

        // let publishedEventMessageOnly = false;
        // if (syncEventDetail.configuration?.companion?.eventMessages) {
        //     if (syncEventDetail.configuration.companion.eventMessages.enabled
        //         && syncEventDetail.configuration.companion.eventMessages.moderateBeforePublish) {
        //         publishedEventMessageOnly = true;
        //     }
        // }

        const allSyncs : (() => Promise<any>)[] = [];
        
        allSyncs.push(() => syncEventThemes(syncArgs).then(trackChanges).then(() => {
            return syncFieldTemplate(syncArgs).then((res) => {
                syncArgs.updatedEntityTemplates = res.updatedTemplates;
                trackChanges(res.hasChanges);
            });
        }));
        allSyncs.push(() => syncSpeakers(syncArgs).then(trackChanges));
        allSyncs.push(() => syncJourneys(syncArgs).then(trackChanges));
        allSyncs.push(() => syncExhibitorOfferings(syncArgs).then(trackChanges));
        allSyncs.push(() => syncSessions(syncArgs).then(trackChanges));
        allSyncs.push(() => syncEventAvailabilities(syncArgs));
        allSyncs.push(() => syncSurvey(syncArgs).then(trackChanges));
        allSyncs.push(() => syncEventTimelineActivities(syncArgs).then(trackChanges));
        allSyncs.push(() => syncExhibitor(syncArgs).then(trackChanges));

        const getNext = () => {
            if (allSyncs.length > 0) {
                const next = allSyncs.shift();
                return next().then(() => {
                    return getNext();
                });
            }
        };

        let nbPipelines = 2;
        if (__SERVERSIDE__) {
            nbPipelines = 4;
        }

        const pipelines: Promise<any>[] = []; 
        for (let i = 0; i < nbPipelines; i++) {
            pipelines.push(getNext());
        }
        
        return Promise.all(pipelines);
        // return syncEventThemes(syncArgs).then(trackChanges).then(() => {
        //     return syncFieldTemplate(syncArgs).then((res) => {
        //         syncArgs.updatedEntityTemplates = res.updatedTemplates;
        //         trackChanges(res.hasChanges);
        //     });
        // }).then(() => {
        //     return syncSpeakers(syncArgs).then(trackChanges);
        // }).then(() => {
        //     return syncSessions(syncArgs).then(trackChanges);
        // }).then(() => {
        //     return syncExhibitorOfferings(syncArgs).then(trackChanges);
        // }).then(() => {
        //     return syncJourneys(syncArgs).then(trackChanges);
        // }).then(() => {
        //     Promise.all([
        //         syncEventAvailabilities(syncArgs),
        //         syncSurvey(syncArgs).then(trackChanges),
        //         syncEventTimelineActivities(syncArgs).then(trackChanges)
        //     ]);
        // }).then(() => {
        //     return syncExhibitor(syncArgs).then(trackChanges);
        // });
        // .then(() => {
        //     return syncExhibitorAccounts(syncArgs).then(trackChanges);
        // });
    }).then(() => {
        //hasChanges = hasChanges || syncHasChanges;
        logger.verbose("DATA core sync ok changes : " + hasChanges);
        let lastSync = eventData.lastsyncs.data.find((s) => s.key === "coresync");
        if (!lastSync) {
            lastSync = {
                key: "coresync",
                date: new Date()
            } as any;
            eventData.lastsyncs.insert(lastSync);
        } else {
            lastSync.date = new Date();
            eventData.lastsyncs.update(lastSync);
        }

        if (eventData.fieldtemplates.data.length) {
            eventData.hasFullData = true;
            let fullSync = eventData.lastsyncs.data.find((s) => s.key === "fullsync");
            if (!fullSync) {
                fullSync = {
                    key: "fullsync",
                    date: new Date()
                } as any;
                eventData.lastsyncs.insert(fullSync);
            } else if (force) {
                fullSync.date = new Date();
                eventData.lastsyncs.update(fullSync);
            }
        }
        eventData.hasData = true;

        return hasChanges;
    }).then(() => {
        if (!disableSave) {
            return eventData.save().then(() => {
                trackProgress({ collectionname: "save", progressPercent: 100 });
            });
        }
    }).then(() => {
        pendingSync[eventid] = null;
        eventData.syncInProgess = false;
        if (dispatch) {
            metadataSyncActions.isSyncingEventData(false)(dispatch);
            if (force) {
                eventActions.eventDataChanged(eventData)(dispatch);
            } else if (hasChanges) {
                eventActions.eventDataChanged()(dispatch);
            }
        }

        globalLastSync[eventid] = new Date();

        return {
            hasChanges: hasChanges,
            eventData: eventData
        };
    }, (err) => {
        logger.error("error syncing data", err);
        pendingSync[eventid] = null;
        eventData.syncInProgess = false;
        if (dispatch) {
            metadataSyncActions.isSyncingEventData(false)(dispatch);
            if (hasChanges) {
                eventActions.eventDataChanged()(dispatch);
            }
        }
        return Promise.reject(err);
    });

    pendingSync[eventid] = syncpromise;

    return syncpromise;
}

// export function syncExtendedFieldsFormTemplate(eventconfig: Entities.IEventDetailConfiguration, eventData: States.IDataStore,
// force: boolean, trackProgress?: any): Promise<any> {
//     var hasChanges = false;
//     return syncEntity<Entities.IExtendedFieldsFormTemplate>(
//         eventData,
//         force,
//         (lastsync) => EventsAPI.entityformstemplates(eventData.eventId, lastsync),
//         "entityformstemplates",
//         (s) => { return { entity: s.entity } },
//         (s) => {
//             hasChanges = true;
//             s.template = s.template ? <Entities.IExtendedFieldsFormGroupTemplate[]>JSON.parse(s.template.toString()) : null;
//         },
//         trackProgress).then(() => hasChanges)
// }
