import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { exhibitorOfferingDatasourceV3 } from '../../api/exhibitorofferings';

export function syncExhibitorOfferings(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args?.updatedEntityTemplates?.some((t) => t.entityName.toLowerCase() === "exhibitoroffering")) {
        hasTemplateUpdate = true;
    }

    if (!args.configuration || !args.configuration.exhibitors || !args.configuration.exhibitors.hasExhibitors) {
        if (args.trackProgress) {
            args.trackProgress({ collectionname: "exhibitorofferings", progressPercent: 100 });
        }
        return Promise.resolve();
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom" }],
            selects: {
                $all: true,
                eventThemes: {
                    id: true,
                },
                journeys: {
                    id: true
                }
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    const itemkey = (r, s) => { return r.id === s.id; };
    const itemcallback = () => {
        hasChanges = true;
    };

    return syncRecursiveEntity<Entities.IExhibitorOffering>({
        datasource: exhibitorOfferingDatasourceV3(args.eventRequests.apiFront),
        logger: args.logger,
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        getBaseOptions: getExpr,
        collectionname: "exhibitorofferings",
        itemkey: itemkey,
        itemcallback: itemcallback,
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
