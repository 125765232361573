import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { exhibitorDatasourceV3, exhibitorAccountDatasourceV3 } from '../../api/exhibitor.datasource';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';

export function syncExhibitor(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args.updatedEntityTemplates && args.updatedEntityTemplates.some((t) => t.entityName.toLowerCase() === "exhibitor")) {
        hasTemplateUpdate = true;
    }

    if (!args.configuration.exhibitors || !args.configuration.exhibitors.hasExhibitors) {
        if (args.trackProgress) {
            args.trackProgress({ collectionname: "exhibitors", progressPercent: 100 });
        }
        return Promise.resolve();
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom" }],
            selects: {
                $all: true,
                eventThemes: {
                    id: true
                },
                availabilities: {
                    id: true
                },
                sessions: {
                    id: true
                },
                journeys: {
                    id: true
                }
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    return syncRecursiveEntity<Entities.IExhibitor>({
        datasource: exhibitorDatasourceV3(args.eventRequests.apiFront),
        getBaseOptions: getExpr,
        logger: args.logger,
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        collectionname: "exhibitors",
        itemcallback: (_s) => {
            const s = _s;
            hasChanges = true;
            s.eventThemes = s.eventThemes ? s.eventThemes.map((sp) => <any>{ id: sp.id }) : null;
            s.sessions = s.sessions ? s.sessions.map((sp) => <any>{ id: sp.id }) : null;
            s.affiliatedToId = s.affiliatedToId ?? null;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}

export function syncExhibitorAccounts(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    if (!args.configuration.exhibitors || !args.configuration.exhibitors.hasExhibitors) {
        if (args.trackProgress) {
            args.trackProgress({ collectionname: "exhibitoraccounts", progressPercent: 100 });
        }
        return Promise.resolve();
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom" }],
            selects: {
                $all: true,
                person: {
                    $all: true
                }
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    const removedProvider = (lastSync) => {
        return exhibitorAccountDatasourceV3(
            args.eventRequests.apiFront
        ).then((ds) => ds.removed(moment(lastSync).toISOString()));
    };

    return syncRecursiveEntity<Entities.IExhibitorAccount>({
        logger: args.logger,
        getBaseOptions: getExpr,
        datasource: exhibitorAccountDatasourceV3(args.eventRequests.apiFront),
        data: args.eventData,
        force: args.force,
        // countCallProvider,
        // apiCallProvider,
        removedProvider,
        collectionname: "exhibitoraccounts",
        itemcallback: () => {
            hasChanges = true;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
