import { Entities } from '@inwink/entities/entities';
import { syncEntity, IEventEntitySync } from '../../../../data/syncutils';
import { getTemplates } from '../../api/template';
// import { oldfieldtemplates } from '../../api/event';

export function syncFieldTemplate(args: IEventEntitySync) {
    const entities = ["Person", "EventTheme", "Session", "ExhibitorOffering"];
    if (args.configuration.exhibitors && args.configuration.exhibitors.hasExhibitors) {
        entities.push("Exhibitor");
    }
    if ((args.configuration.exhibitors && args.configuration.exhibitors.allowExhibitorScan)
        || (args.configuration.registered && args.configuration.registered.allowRegisteredScan)) {
        entities.push("ExhibitorScan");
    }
    if (args.configuration.registration && args.configuration.registration.isPaidRegistration) {
        entities.push("Order");
        entities.push("Pass");
    }
    return syncFieldTemplates(entities, args);
}

export function syncFieldTemplates(templateNames: string[], args: IEventEntitySync) {
    let hasChanges = false;
    const updatedTemplates: Entities.IFieldTemplate[] = [];

    return syncEntity<Entities.IFieldTemplate>({
        requestMgr: args.eventRequests.apiFront,
        logger: args.logger,
        dataStore: args.eventData,
        force: args.force,
        apiCallProvider: () => {
            return getTemplates(args.eventRequests, templateNames);
        },
        collectionname: "fieldtemplates",
        itemkey: (r, s) => { return r.entityName === s.entityName; },
        itemcallback: (item) => {
            const existing = args.eventData.fieldtemplates.data.find((e) => e.entityName === item.entityName);
            if (!existing || !existing.workTemplate
                || (existing.workTemplate as any).version !== (item.workTemplate as any)?.version) {
                updatedTemplates.push(item);
                hasChanges = true;
            }
        },
        trackProgress: args.trackProgress,
        rewriteUrls: args.rewriteUrls
    }).then(() => {
        return {
            hasChanges,
            updatedTemplates
        };
    });
}
