import type { Entities } from '@inwink/entities/entities';
import type { IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function eventTimelineActivityDatasourceV3(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IEventTimelineActivity>(
            requestManager, "eventtimelineactivity", "EventTimelineActivity"
        );
    });
}
