import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';
import { syncEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import { surveyDatasourceV3 } from '../../api/survey';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';

export function syncSurvey(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    return syncEntity<Entities.ISurvey>({
        logger: args.logger,
        requestMgr: args.eventRequests.apiFront,
        dataStore: args.eventData,
        force: args.force,
        apiCallProvider: (lastsync) => {
            const and: any[] = [
                { allowOffline: true }
            ];

            if (lastsync) {
                and.push({ validFrom: { $gt: getDate(moment(lastsync), true) }});
            }

            const options = {
                order: [{ by: "validFrom" }],
                filters: { $and: and },
                selects: {
                    $all: true
                }
            } as IInwinkEntityV3QueryOptions;
            return surveyDatasourceV3(
                args.eventRequests.apiFront
            ).then((ds) => ds.getAll(options)).then((res) => res.data);
        },
        collectionname: "surveys",
        itemkey: (r, s) => { return r.id === s.id; },
        itemcallback: () => {
            hasChanges = true;
        },
        trackProgress: args.trackProgress
    }).then(() => hasChanges);
}
