import { States } from "@@services/services";
import { eventBootstrap } from '../eventservice.initevent';

let pendingBootstrap : Promise<any> = null;

export const bootstrapEventActions = {
    eventBootstrap(
        isFromServer: boolean,
        location: States.ILocation,
        matches: States.ILocationMatch,
        eventid: string,
        tinyurl: string,
        forceload = false
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            if (__SERVERSIDE__) {
                return eventBootstrap(isFromServer, eventid, tinyurl, location, matches, forceload, dispatch, getState);
            }

            if (pendingBootstrap && !forceload) {
                const currentState = getState();
                const isCurrentEvent = currentState.event?.detail && currentState.event?.detail.tinyUrl === tinyurl;
                if (isCurrentEvent || !currentState.event?.detail) {
                    return pendingBootstrap;
                }
            }

            pendingBootstrap = eventBootstrap(isFromServer, eventid, tinyurl, location, matches, forceload, dispatch, getState);
            pendingBootstrap.then(() => {
                pendingBootstrap = null;
            }, () => {
                pendingBootstrap = null;
            });

            return pendingBootstrap;
        };
    }
};
