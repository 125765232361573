import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '../../../../data/syncutils';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';

export function syncSpeakers(args: IEventEntitySync): Promise<any> {
    let hasChanges = false;
    let hasTemplateUpdate = false;

    if (args.updatedEntityTemplates && args.updatedEntityTemplates.some((t) => t.entityName.toLowerCase() === "person")) {
        hasTemplateUpdate = true;
    }

    const getExpr = (lastSync) => {
        const initialOption: IInwinkEntityV3QueryOptions = {
            order: [{ by: "validFrom"}],
            selects: {
                $all: true,
                animatedSessions: {
                    id: true
                },
                journeys: {
                    id: true
                }
            }
        };

        if (lastSync) {
            initialOption.filters = { validFrom: { $gt: getDate(moment(lastSync), true) }};
        }

        return initialOption;
    };

    const itemkey = (r, s) => { return r.id === s.id; };
    const itemcallback = (_s) => {
        const s = _s;
        hasChanges = true;
        s.sessions = s.animatedSessions ? s.animatedSessions.map((sp) => <any>{ id: sp.id }) : null;
    };

    return syncRecursiveEntity<Entities.IPerson>({
        logger: args.logger,
        getBaseOptions: getExpr,
        datasource: import("../../../../api/front/datasource").then((mod) => mod.getFrontEntityDataSource(
            args.eventRequests.apiFront, "speaker", "Speaker"
        )),
        data: args.eventData,
        force: hasTemplateUpdate || args.force,
        collectionname: "speakers",
        itemkey,
        itemcallback,
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);
}
