import { States } from "@@services/services";
import { appSplashActions } from '@@services/splashservice';
import { eventModule } from '@@routes/appmodules';
import { IEventRequests } from '@@services/apiaccessprovider.definition';

export function syncEventData(
    eventRequests: IEventRequests,
    splashservicekey: string,
    eventid: string,
    eventData: States.IEventDataStore,
    force: boolean,
    disableFrequentSyncCheck: boolean,
    dispatch,
    getState: () => States.IAppState
) {
    const progresscallback = (arg: { progressPercent: number }) => {
        appSplashActions.progress(splashservicekey, {
            progress: arg.progressPercent
        })(dispatch);
    };

    if (console && console.timeStamp) {
        console.timeStamp("startup sync event start");
    }
    return eventModule().then((mod) => {
        return mod.syncEventActions.syncEventData(
            eventRequests,
            eventid,
            eventData,
            force,
            progresscallback,
            disableFrequentSyncCheck
        )(dispatch, getState);
    });
}
