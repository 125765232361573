import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import type { IInwinkEntityV3QueryOptions } from 'api/front';
import { syncRecursiveEntity, IEventEntitySync, getDate } from '@@data/syncutils';
import { contentTemplateDatasourceV3 } from '@@event/api/contenttemplate';

export function syncContentTemplates(args: IEventEntitySync) {
    let templateState = "published";
    if (InWinkPreview) {
        templateState = "preview";
    }

    let hasChanges = false;
    
    const datasource = contentTemplateDatasourceV3(args.eventRequests.apiFront);

    const targetApplications = [{ application: "event" }, { application: "companion" }, { application: "userwrk" }];
    if (__SERVERSIDE__) {
        targetApplications.push({ application: "companion-server" });
    }

    const getExpr = (lastSync) => {
        const filters: any = {
            $or: targetApplications,
            state: templateState
        };
        if (lastSync) {
            filters.validFrom = { $gt: getDate(moment(lastSync), true) };
        }
        const options: IInwinkEntityV3QueryOptions = {
            filters: filters,
            order: [{ by: "validFrom" }],
            selects: {
                $all: true
            }
        } as IInwinkEntityV3QueryOptions;

        return options;
    };

    
    const syncEntities = syncRecursiveEntity<Entities.IContentTemplate>({
        datasource: datasource,
        getBaseOptions: getExpr,
        logger: args.logger,
        data: args.eventData,
        force: args.force,
        collectionname: "templates",
        itemcallback: () => {
            hasChanges = true;
        },
        progressCallback: args.trackProgress,
        itemsPerRequest: 100,
        rewriteUrls: args.rewriteUrls
    }).then(() => hasChanges);

    return syncEntities;
}
