import type { Entities } from '@inwink/entities/entities';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData, IInwinkEntityV3QuerySelects } from '../../../api/front/index';

export function exhibitorOfferingDatasourceV3(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IExhibitorOffering>(
            requestManager, "exhibitoroffering", "ExhibitorOffering"
        );
    });
}

export function partnerExhibitorOfferingDatasource(eventRequests: IEventRequests, exhibitorId: string) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IExhibitorOffering>(eventRequests.apiFront,
            `partner/${exhibitorId}/offering`, "ExhibitorOffering");
    });
}

export function partnerEditExhibitorOffering(eventRequests: IEventRequests, exhibitorId: string,
    offeringId: string, offering: Partial<Entities.IExhibitorOffering>, selects?: IInwinkEntityV3QuerySelects) {
    return eventRequests.apiFront.postEventJson<Entities.IExhibitorOffering>(`partner/${exhibitorId}/offering/edit/${offeringId}`,
        JSON.stringify({
            entity: offering,
            selects: selects
        }), defaultPostHeaderForJsonData);
}

export function partnerDeleteExhibitorOffering(eventRequests: IEventRequests, exhibitorId: string,
    offeringId: string): Promise<number> {
    return eventRequests.apiFront.postEventJson(`partner/${exhibitorId}/offering/delete/${offeringId}`,
        JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function partnerAddExhibitorOffering(eventRequests: IEventRequests, exhibitorId: string,
    offering: Partial<Entities.IExhibitorOffering>) {
    return eventRequests.apiFront.postEventJson<Entities.IExhibitorOffering>(`partner/${exhibitorId}/offering`, JSON.stringify({
        entity: {
            exhibitorId: exhibitorId,
            ...offering
        }
    }), defaultPostHeaderForJsonData);
}
